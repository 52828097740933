
import { Component, Mixins } from 'vue-property-decorator';
import { orgAdminStore } from '@/store';
import Page from '@/views/Page.vue';
import OrgCard from '@/components/orgAdmin/OrgCard.vue';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';
import { VuetifyMixin, DebounceMixin, LocalForageMixin, PhysicalAssessmentMixin, ScoutingReportMixin, MindsetReportMixin, OrgResolverMixin, BAIconsMixin, CoachRoutingMixin } from '@/mixins';
import { title } from '@/pipes/title.pipe';
import { Gender, PerformanceCategory, getPerformanceCategoryValues } from '@/../types/enums';
import { AthleteMetric, AthleteMetricValues, FourCornersMetric, SixMomentsMetric, CrispMetric, MindsetMetric } from '@/../types/enums';
import { AgeGroup, AgeGroupValues, DominantSide, DominantSideValues } from '@best-athletes/ba-types';
import { RepositoryQuery, QueryOptions } from '@/../types/interfaces';
import ChipGroup from '@/components/forms/ChipGroup.vue';
import TeamsSummary from '@/components/orgAdmin/TeamsSummary.vue';
import AssessmentDataInfoProvider from '@/components/hoc/AssessmentDataInfoProvider.vue';
import { performanceCategoryToColor, performanceCategoryToLabel } from '@/pipes/enum.pipes';
import { assessmentToMetric } from '@/pipes/assessment-to-metric.pipe';
import { DataTableHeader, DataOptions } from 'vuetify';
import { CoachViewAthlete } from '@/../types/constants/web_client_user.routes';
import { BaseSoccerPosition, BaseSoccerPositionValues } from '@/../types/enums/sports';
import { Comparison } from '@/../types/interfaces/Statistics';
import AssessmentChip from '../../components/orgAdmin/AssessmentChip.vue';
import ScoutingChip from '../../components/orgAdmin/ScoutingChip.vue';
import MindsetChip from '../../components/orgAdmin/MindsetChip.vue';
import ToggleButton from '@/components/ui/ToggleButton.vue';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { TeamModel } from '@/models/team';
import { teamApi } from '@/api/TeamApi';

class AthleteProfileInfoModel extends AthleteProfileModel {
	teams: Array<TeamModel>
}

@Component({
	components: {
		AssessmentDataInfoProvider,
		ConfirmationDialog,
		ChipGroup,
		OrgCard,
		Page,
		TeamsSummary,
		AssessmentChip,
		ScoutingChip,
		MindsetChip,
		ToggleButton
	}
})
export default class OrgAdminManagePlayers extends Mixins(
		DebounceMixin,
		LocalForageMixin,
		OrgResolverMixin,
		VuetifyMixin,
		PhysicalAssessmentMixin,
		ScoutingReportMixin,
		MindsetReportMixin,
		BAIconsMixin,
		CoachRoutingMixin,
	){
	assessmentToMetric = assessmentToMetric;
	titleCase = title;
	AthleteMetric = AthleteMetric;
	FourCornersMetric = FourCornersMetric;
	SixMomentsMetric = SixMomentsMetric;
	CrispMetric = CrispMetric;
	MindsetMetric = MindsetMetric;
	panel = [0];
	

	// called when item is created - perform default search
	created(): void{
		this.debounceSearch();
	}

	// viewStats indicates which stats should be displayed
	viewCategory = "category-physical";
	viewPhysical = "physical-verified";
	viewScouting = "scouting-fourcorners";
	viewMindset = "mindset-overall";
	viewPhysicalView = "physical-view-measured"


	get isViewPhysical(): boolean {
		return this.viewCategory === 'category-physical';
	}
	get isViewPhysicalMeasured(): boolean {
		return this.isViewPhysical && this.viewPhysicalView == "physical-view-measured";
	}
	get isViewPhysicalDerived(): boolean {
		return this.isViewPhysical && this.viewPhysicalView == "physical-view-derived";
	}
	get isViewScouting(): boolean {
		return this.viewCategory === 'category-scouting';
	}
	get isViewFourCorners(): boolean {
		return this.viewCategory === 'category-scouting' && this.viewScouting === "scouting-fourcorners";
	}
	get isViewSixMoments(): boolean {
		return this.viewCategory === 'category-scouting' && this.viewScouting === "scouting-sixmoments";
	}
	get isViewCrisp(): boolean {
		return this.viewCategory === 'category-scouting' && this.viewScouting === "scouting-crisp";
	}
	get isViewMindset(): boolean {
		return this.viewCategory === 'category-mindset'
	}
	get isViewMindsetOverall(): boolean {
		return this.viewCategory === 'category-mindset' && this.viewMindset === "mindset-overall";
	}
	get isViewMentalToughness(): boolean {
		return this.viewCategory === 'category-mindset' && this.viewMindset == "mental-toughness";
	}
	get isViewMentalExecution(): boolean {
		return this.viewCategory === 'category-mindset' && this.viewMindset == "mental-execution";
	}
	get isViewCoachability(): boolean {
		return this.viewCategory === 'category-mindset' && this.viewMindset == "coachability";
	}

	getAssessmentColor(comparison: Comparison | null, metric: AthleteMetric): string{
		if( !comparison ) return 'baColorSecondaryText';
		return performanceCategoryToColor(comparison[metric]);
	}

	performanceCategoryToColor = performanceCategoryToColor;
	performanceCategoryToLabel = performanceCategoryToLabel;
	get PerformanceCategories(): PerformanceCategory[]{
		return getPerformanceCategoryValues(true);
	}

	resetFilters(): void{
		this.playerSearch = "";
		this.programOfStudyFilter = "";
		this.graduationYearFilter = "";
		this.ageFilter = [];
		this.positionFilter = [];
		this.dominantSideFilter = [];
		this.genderFilter = [];
		this.performanceCategoryFilters = this.emptyPerfFilters();
		this.debounceSearch();
	}

	private emptyPerfFilters(): Record<AthleteMetric, Array<PerformanceCategory>>{
		const filters = AthleteMetricValues.reduce((filters, metric) => {
			filters[metric] = [];
			return filters;
		}, {});
		return filters as Record<AthleteMetric, Array<PerformanceCategory>>;
	}
	performanceCategoryFilters = this.emptyPerfFilters();

	ageFilter: AgeGroup[] = [];
	get AgeGroupValues(): AgeGroup[]{
		return AgeGroupValues;
	}
	get AgeFilter(): number[]{
		return this.ageFilter.map(ageGroup => {
			return parseInt(ageGroup.replace(/[A-Za-z]/, ''));
		});
	}

	positionFilter: BaseSoccerPosition[] = [];
	get PositionValues(): BaseSoccerPosition[]{
		return BaseSoccerPositionValues;
	}

	dominantSideFilter: DominantSide[] = [];
	get DominantSideValues(): DominantSide[]{
		return DominantSideValues;
	}

	genderFilter: Gender[] = [];
	get TeamGenderValues(): Gender[]{
		return [
			Gender.Male,
			Gender.Female,
		];
	}

	maxNumOfPlayer: number = 0;
	get TotalPlayers(): Record<any,number>{
		this.maxNumOfPlayer = Math.max(this.maxNumOfPlayer, orgAdminStore.totalPlayers);
		return ({
			'totalMax' :this.maxNumOfPlayer,
			'filteredMax': orgAdminStore.totalPlayers
		})
	}
	get PlayerList(): AthleteProfileModel[]{
		return orgAdminStore.playersList;
	}

	searchLoading: boolean = false;
	playerSearch: string = "";
	debounceSearch(): void{
		this.searchLoading = true;
		this.debounceCallback('playerSearch', () => { this.queryOrgPlayers(); }, 300);
	}

	debounceRowsPerPage() {
		this.tableOptions.page = 1;
		this.debounceCallback('rowsPerPage', () => { this.queryOrgPlayers(); }, 300);
	}

	graduationYearFilter: string = '';
	debounceGradYearFilter() {
		this.searchLoading = true;
		this.debounceCallback('graduationYearFilter', () => { this.queryOrgPlayers(); }, 300);
	}

	programOfStudyFilter: string = '';
	debounceProgramOfStudyFilter(): void {
		this.searchLoading = true;
		this.debounceCallback('programOfStudyFilter', () => { this.queryOrgPlayers(); }, 300);
	}

	debounceSortBy() {
		if( this.tableOptions.sortBy.length === 0 ) return;

		this.tableOptions.sortDesc[0] = true;
		switch( this.tableOptions.sortBy[0] ){
			case 'lastAssessment.twentyToThirtyFiveMeterSplit':
			case 'lastAssessment.tenMeterSprint':
				this.tableOptions.sortDesc[0] = false;
				break;
		}
		this.debounceSort();
	}
	debounceSort() {
		this.searchLoading = true;
		this.debounceCallback('sort', () => { this.queryOrgPlayers(); }, 300);
	}

	async queryOrgPlayers(): Promise<void>{
		this.searchLoading = true;

		const query: RepositoryQuery<any> = {
			$and: [],
		};

		let options: QueryOptions = { 
			page: this.tableOptions.page,
			limitPerPage: this.tableOptions.itemsPerPage,
		};

		if (this.tableOptions.itemsPerPage == -1) {
			options = { 
				page: this.tableOptions.page,
				limitPerPage: 9999,
			}
		}

		if( this.tableOptions.sortBy.length > 0 ){
			options.sort = {
				fields: this.tableOptions.sortBy.map((field, index) => {
					return {
						field: field,
						desc: this.tableOptions.sortDesc[index],
					};
				}),
			};
		}

		if(this.AgeFilter.length > 0){
			query.$and.push({
				Age: {
					$any: this.AgeFilter
				}
			})
		}

		if(this.positionFilter.length > 0){
			query.$and.push({
				$or: [
					{
					primaryPosition: {
						$any: this.positionFilter
					}},
					{
					secondaryPosition: {
						$any: this.positionFilter
					}}
				]
			})
		}

		if(this.dominantSideFilter.length > 0){
			query.$and.push({
				dominantSide: {
					$any: this.dominantSideFilter
				}
			})
		}

		if(this.genderFilter.length > 0){
			query.$and.push({
				gender:{
					// Search for "Male" and "male" since the gender isn't using enums everywhere
					$any: this.genderFilter.map(g => [g.toLowerCase(), title(g)]).reduce((a,b) => [...a,...b]),
				}
			})
		}

		if( this.playerSearch && this.playerSearch.length > 0 ) {
			query.$and.push({
				$search: {
					query: `${this.playerSearch}`,
					fields: ['firstName', 'lastName'],
					type: "phrase_prefix",
					boost: 2,
				}
			})
		}

		if( this.graduationYearFilter && this.graduationYearFilter.length > 0 ) {
			query.$and.push({
				$search: {
					query: `${this.graduationYearFilter}`,
					fields: [`gradYear`],
					type: "phrase"
				}
			})
		}
		if( this.programOfStudyFilter && this.programOfStudyFilter.length > 0 ) {
			query.$and.push({
				$search: {
					query: `${this.programOfStudyFilter}`,
					fields: ['anticipatedStudy'], 
					type: "phrase_prefix",
				}
			})
		}

		for(const metric of Object.keys(this.performanceCategoryFilters)){
			if(this.performanceCategoryFilters[metric].length > 0){
				query.$match = {
					...query.$match,
					[`lastAssessmentComparison.${metric}`]: {
						$any: this.performanceCategoryFilters[metric]
					}
				}
			}
		}
		this.searchLoading = true;
		await orgAdminStore.loadOrganizationPlayers({ organizationId: this.organizationId, query, options });
		await orgAdminStore.loadOrganizationTeams({ organizationId: this.organizationId})
		this.searchLoading = false;
	}

	// headers for physical assessments
	headersPhysical: Array<DataTableHeader<any> & { metric?: AthleteMetric }> = [
		{
			text: 'Spd',
			value: 'lastAssessment.twentyToThirtyFiveMeterSplit',
			sortable: true,
			width: 82,
			metric: AthleteMetric.Speed,
		},
		{
			text: 'Accl',
			value: 'lastAssessment.tenMeterSprint',
			sortable: true,
			width: 82,
			metric: AthleteMetric.Acceleration,
		},
		{
			text: 'Agl',
			value: 'lastAssessment.reactiveStrengthIndex',
			sortable: true,
			width: 82,
			metric: AthleteMetric.Agility,
		},
		{
			text: 'Pow',
			value: 'lastAssessment.counterMovementJumpHeight',
			sortable: true,
			width: 82,
			metric: AthleteMetric.Power,
		},
		{
			text: 'Rec',
			value: 'lastAssessment.yoyoIntermittentRecoveryTestStage',
			sortable: true,
			width: 82,
			metric: AthleteMetric.Recovery,
		},
	]

	headersFourCorners: Array<DataTableHeader<any> & { metric?: FourCornersMetric}> = [
		{
			text: "Tech",
			value: "scoutingReport.technical",
			sortable: true,
			width: 82,
			metric: FourCornersMetric.Technical,
		},
		{
			text: "Tact",
			value: "scoutingReport.tactical",
			sortable: true,
			width: 82,
			metric: FourCornersMetric.Tactical,
		},
		{
			text: "Phys",
			value: "scoutingReport.physical",
			sortable: true,
			width: 82,
			metric: FourCornersMetric.Physical,
		},
		{
			text: "Ment",
			value: "scoutingReport.mental",
			sortable: true,
			width: 82,
			metric: FourCornersMetric.Mental,
		}
	]

	headersSixMoments: Array<DataTableHeader<any> & { metric?: SixMomentsMetric}> = [
		{
			text: "A.Or",
			value: "scoutingReport.attackingOrganization",
			sortable: true,
			width: 82,
			metric: SixMomentsMetric.AttackingOrganization,
		},
		{
			text: "A.Tr",
			value: "scoutingReport.attackingTransition",
			sortable: true,
			width: 82,
			metric: SixMomentsMetric.AttackingTransition,
		},
		{
			text: "A.SP",
			value: "scoutingReport.attackingSetPlays",
			sortable: true,
			width: 82,
			metric: SixMomentsMetric.AttackingSetPlays,
		},
		{
			text: "D.Or",
			value: "scoutingReport.defendingOrganization",
			sortable: true,
			width: 82,
			metric: SixMomentsMetric.DefendingOrganization,
		},
		{
			text: "D.Tr",
			value: "scoutingReport.defendingTransition",
			sortable: true,
			width: 82,
			metric: SixMomentsMetric.DefendingTransition,
		},
		{
			text: "D.SP",
			value: "scoutingReport.defendingSetPlays",
			sortable: true,
			width: 82,
			metric: SixMomentsMetric.DefendingSetPlays,
		},
	]

	headersCrisp: Array<DataTableHeader<any> & { metric?: CrispMetric}> = [
		{
			text: "Cmp",
			value: "scoutingReport.competitive",
			sortable: true,
			width: 82,
			metric: CrispMetric.Competitive,
		},
		{
			text: "Res",
			value: "scoutingReport.resilience",
			sortable: true,
			width: 82,
			metric: CrispMetric.Resilience,
		},
		{
			text: "Int",
			value: "scoutingReport.intelligence",
			sortable: true,
			width: 82,
			metric: CrispMetric.Intelligence,
		},
		{
			text: "Spd",
			value: "scoutingReport.speed",
			sortable: true,
			width: 82,
			metric: CrispMetric.Speed,
		},
		{
			text: "Prs",
			value: "scoutingReport.presence",
			sortable: true,
			width: 82,
			metric: CrispMetric.Presence,
		},
	]

	headersPersonality: Array<DataTableHeader<any> & { metric: MindsetMetric}> = [
		{
			text: "M.Tgh",
			value: "mindsetReport.mentalToughness",
			sortable: true,
			width: 82,
			metric: MindsetMetric.MentalToughness,
		},
		{
			text: "M.Exc",
			value: "mindsetReport.mentalExecution",
			sortable: true,
			width: 82,
			metric: MindsetMetric.MentalExecution,
		},
		{
			text: "Coach",
			value: "mindsetReport.coachability",
			sortable: true,
			width: 82,
			metric: MindsetMetric.Coachability,
		},
	]

	headersMentalToughness: Array<DataTableHeader<any> & { metric: MindsetMetric}> = [
		{
			text: "Cpsr",
			value: "mindsetReport.composure",
			sortable: true,
			width: 82,
			metric: MindsetMetric.Composure,
		},
		{
			text: "Cnfd",
			value: "mindsetReport.confidence",
			sortable: true,
			width: 82,
			metric: MindsetMetric.Confidence,
		},
		{
			text: "Driv",
			value: "mindsetReport.drive",
			sortable: true,
			width: 82,
			metric: MindsetMetric.Drive,
		},
		{
			text: "Grit",
			value: "mindsetReport.grit",
			sortable: true,
			width: 82,
			metric: MindsetMetric.Grit,
		},
	]

	headersMentalExecution: Array<DataTableHeader<any> & { metric: MindsetMetric}> = [
		{
			text: "Adapt",
			value: "mindsetReport.adaptability",
			sortable: true,
			width: 82,
			metric: MindsetMetric.Adaptability,
		},
		{
			text: "Dcvns",
			value: "mindsetReport.decisiveness",
			sortable: true,
			width: 82,
			metric: MindsetMetric.Decisiveness,
		},
		{
			text: "F.Spd",
			value: "mindsetReport.focusSpeed",
			sortable: true,
			width: 82,
			metric: MindsetMetric.FocusSpeed,
		},
	]

	headersCoachability: Array<DataTableHeader<any> & { metric: MindsetMetric}> = [
		{
			text: "Detail",
			value: "mindsetReport.attentionToDetail",
			sortable: true,
			width: 82,
			metric: MindsetMetric.AttentionToDetail,
		},
		{
			text: "Cnvntl",
			value: "mindsetReport.conventionality",
			sortable: true,
			width: 82,
			metric: MindsetMetric.Conventionality,
		},
		{
			text: "Gr.Mnd",
			value: "mindsetReport.growthMindset",
			sortable: true,
			width: 82,
			metric: MindsetMetric.GrowthMindset,
		},
		{
			text: "Inf.Pr",
			value: "mindsetReport.informationProcessing",
			sortable: true,
			width: 82,
			metric: MindsetMetric.InformationProcessing,
		},		
	]

	get TableHeaders(): Array<DataTableHeader<any>>{
		var headersBasic: Array<DataTableHeader<any>> = [
			{ text: 'Name', value: 'lastName', sortable: false }
		]

		if( this.ShowAge ) {
			headersBasic.push(
				{ text: 'Age', value: 'Age', sortable: true }
			)
		}

		if( this.ShowGender ) {
			headersBasic.push(
				{ text: 'G', value: 'gender', sortable: false },
			)
		}

		if( this.ShowPos ) {
			headersBasic.push(
				{ text: 'Pos', value: 'primaryPosition', sortable: false },
			)
		}
		
		if( this.ShowTeam ) {
			headersBasic.push(
				{ text: 'Team', value: 'teams', sortable: false },
			)
		}

		if( this.isViewFourCorners ) return headersBasic.concat(this.headersFourCorners);
		if( this.isViewSixMoments ) return headersBasic.concat(this.headersSixMoments);
		if( this.isViewCrisp ) return headersBasic.concat(this.headersCrisp);
		if( this.isViewMindsetOverall ) return headersBasic.concat(this.headersPersonality);
		if( this.isViewMentalToughness ) return headersBasic.concat(this.headersMentalToughness);
		if( this.isViewMentalExecution ) return headersBasic.concat(this.headersMentalExecution);
		if( this.isViewCoachability ) return headersBasic.concat(this.headersCoachability);
		return headersBasic.concat(this.headersPhysical);
	} 

	get CurrentViewItems() {
		if( this.isViewPhysical ) return this.PhysicalTestsInfo();
		if( this.isViewScouting ) return this.ScoutingReportsInfo();
		if( this.isViewMindset ) return this.MindsetReportsInfoItems;
	}

	/**
	 * Athlete Metrics listed in order identical to headers
	 */
	get PhysicalMetrics(): AthleteMetric[]{
		return this.headersPhysical.filter(h => h.metric !== undefined).map(h => h.metric!);
	}	
	get FourCornersMetrics(): FourCornersMetric[]{
		return this.headersFourCorners.filter(h => h.metric !== undefined).map(h => h.metric!);
	}
	get SixMomentsMetrics(): SixMomentsMetric[]{
		return this.headersSixMoments.filter(h => h.metric !== undefined).map(h => h.metric!);
	}
	get CrispMetrics(): CrispMetric[]{
		return this.headersCrisp.filter(h => h.metric !== undefined).map(h => h.metric!);
	}
	get MindsetMetrics(): MindsetMetric[]{
		return this.headersPersonality.filter(h => h.metric !== undefined).map(h => h.metric!);
	}

	get ShowAge(): boolean {
		return true;
	}
	get ShowGender(): boolean {
		return false;
	}
	get ShowPos(): boolean {
		return true;
	}
	get ShowTeam(): boolean {
		return true;
	}
	tableOptions: DataOptions = {
		page: 1,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: true,
	};

	get TableLoading(): boolean{
		return !orgAdminStore.loadOrganizationPlayersInitialized || orgAdminStore.loadOrganizationPlayersLoading;
	}

	get InformationText(): string {
		if( this.isViewPhysical ) return "Information about Physical Assessments";
		if( this.isViewScouting ) return "Information about Scouting Reports";
		if( this.isViewMindset ) return "Information about Mindset Reports";

		return "Information";
	}

	get DefaultBtnColor(): string {
		return '';
	}
	PrimaryBtnColor(enabled: boolean): string {
		if( enabled ) return 'primary white--text';
		return this.DefaultBtnColor;
	}
	SecondaryBtnColor(enabled: boolean): string {
		if( enabled ) return 'secondary black--text';
		return this.DefaultBtnColor;
	}
	get PrimaryBtnSize(): number {
		if( this.IsSmallScreen ) return 120;
		return 210;
	}
	get SecondaryBtnSize(): number {
		if( this.IsSmallScreen ) return 100;
		return 180;
	}
	
    get RowsPerPageItems() {
        return [
            { name: '10', value: 10 },
            { name: '25', value: 25 },
            { name: '100', value: 100 },
        ]
    }
	get TotalItems(): number {
		return orgAdminStore.totalPlayers;
	}
	get TotalPages(): number {
		return Math.ceil(this.TotalItems / this.tableOptions.itemsPerPage);
	}

	CurrentTeam(athlete: AthleteProfileInfoModel): string {
		if( this.IsEmpty(athlete) ) return 'n/a';
		if( this.IsNotEmpty(athlete.currentTeam && orgAdminStore.teamList) ) {
			let team = orgAdminStore.teamList.find(t => t.id === athlete.currentTeam);
			if( this.IsNotEmpty(team) ) return team.name;
		}
		if( athlete.teams.length > 0 ) return athlete.teams[athlete.teams.length - 1].name;
		return 'no team';
	}
}

