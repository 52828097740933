
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin } from "../../mixins/VuetifyMixin";

@Component
export default class ToggleButton extends Mixins(VuetifyMixin) {
	@Prop({ required: true, type: String }) value;
	@Prop({ type: Boolean }) primary;
	@Prop({ type: Boolean }) secondary;
	@Prop({ type: Boolean }) on;
	@Prop({ type: Boolean }) iconAfter;
	@Prop({ type: Boolean }) small;
	@Prop({ type: Boolean }) disabled;
	@Prop({ type: Boolean }) nosize;
	@Prop({ type: String }) icon = undefined;
	@Prop({ type: String }) smallText = undefined;

	onClick(event): void {
		if( this.on ) event.stopPropagation();
		this.$emit("click");
	}
	get PrimaryBtnSize(): number {
		if( this.nosize ) return 0;
		if( this.IsSmallScreen ) return 120;
		return 210;
	}
	get SecondaryBtnSize(): number {
		if( this.nosize ) return 0;
		if( this.IsSmallScreen ) return 95;
		return 163;
	}
	get BtnSize(): number {
		if( this.primary ) return this.PrimaryBtnSize;
		if( this.secondary ) return this.SecondaryBtnSize;
		return 0;
	}

	get DefaultBtnColor(): string {
		return '';
	}
	PrimaryBtnColor(enabled: boolean): string {
		if( enabled ) return 'primary white--text';
		return this.DefaultBtnColor;
	}
	SecondaryBtnColor(enabled: boolean): string {
		if( enabled ) return 'secondary black--text';
		return this.DefaultBtnColor;
	}
	get BtnColor(): string {
		if( this.primary ) return this.PrimaryBtnColor(this.on);
		if( this.secondary ) return this.SecondaryBtnColor(this.on);
		return this.DefaultBtnColor;
	}

	get HasIconBefore(): boolean {
		if( !this.icon ) return false;
		return !this.iconAfter;
	}
	get HasIconAfter(): boolean {
		if( !this.icon ) return false;
		return this.iconAfter;
	}

	get IconColor(): string {
		if( this.on )
		{
			if( this.primary ) return 'white--text';
			if( this.secondary ) return 'black--text';
		}
		return '';
	}

	get UseSmallText(): boolean {
		return this.IsSmallScreen && this.HasSmallText;
	}
	get HasSmallText(): boolean {
		return !!this.smallText;
	}
}
