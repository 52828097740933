
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { MindsetMetric } from '@/../types/enums';
import { OrganizationMindset } from '@/models/organization/organizationPlayer/OrganizationPlayerModel';

@Component
export default class MindsetChip extends Mixins(VuetifyMixin){
	@Prop({ default: null }) mindsetReport: OrganizationMindset | null;
	@Prop() metric: MindsetMetric;

	get MindsetValue(): number {
		if( !this.mindsetReport ) return 0;

		else if( this.metric == MindsetMetric.MentalToughness ) return this.mindsetReport.mentalToughness;
		else if( this.metric == MindsetMetric.Composure ) return this.mindsetReport.composure;
		else if( this.metric == MindsetMetric.Confidence ) return this.mindsetReport.confidence;
		else if( this.metric == MindsetMetric.Drive ) return this.mindsetReport.drive;
		else if( this.metric == MindsetMetric.Grit ) return this.mindsetReport.grit;

		else if( this.metric == MindsetMetric.MentalExecution ) return this.mindsetReport.mentalExecution;
		else if( this.metric == MindsetMetric.Adaptability ) return this.mindsetReport.adaptability;
		else if( this.metric == MindsetMetric.Decisiveness ) return this.mindsetReport.decisiveness;
		else if( this.metric == MindsetMetric.FocusSpeed ) return this.mindsetReport.focusSpeed;

		else if( this.metric == MindsetMetric.Coachability ) return this.mindsetReport.coachability;
		else if( this.metric == MindsetMetric.AttentionToDetail ) return this.mindsetReport.attentionToDetail;
		else if( this.metric == MindsetMetric.Conventionality ) return this.mindsetReport.conventionality;
		else if( this.metric == MindsetMetric.GrowthMindset ) return this.mindsetReport.growthMindset;
		else if( this.metric == MindsetMetric.InformationProcessing ) return this.mindsetReport.informationProcessing;
		
		return 0;
	}

	get MindsetValueString(): string {
		if( !this.mindsetReport ) return "-";
		if( this.MindsetValue < 1 ) return "-";

		return this.MindsetValue.toFixed(0);
	}

	get ToolTipColor(): string {
		if( !this.mindsetReport ) return 'error';
		if( this.MindsetValue < 1 ) return 'error';

		return this.MindsetColor;
	}

	get MindsetColor(): string {
		if( !this.mindsetReport ) return 'primary--text';
		if( this.MindsetValue < 1 ) return 'primary--text';

		if( this.MindsetValue > 95 ) return 'baColorProfessional';
		else if( this.MindsetValue >= 55 ) return 'baColorHighPerformance';
		else if( this.MindsetValue >= 35 ) return 'baColorCompetitive';
		else if( this.MindsetValue > 10 ) return 'baColorRecreational';
		return 'baColorUnranked';
	}

	get MindsetLabel(): string {
		if( !this.mindsetReport ) return '';

		else if( this.metric == MindsetMetric.MentalToughness ) return 'Mental Toughness';
		else if( this.metric == MindsetMetric.Composure ) return 'Composure';
		else if( this.metric == MindsetMetric.Confidence ) return 'Confidence';
		else if( this.metric == MindsetMetric.Drive ) return 'Drive';
		else if( this.metric == MindsetMetric.Grit ) return 'Grit';

		else if( this.metric == MindsetMetric.MentalExecution ) return 'Mental Execution';
		else if( this.metric == MindsetMetric.Adaptability ) return 'Adaptability';
		else if( this.metric == MindsetMetric.Decisiveness ) return 'Decisiveness';
		else if( this.metric == MindsetMetric.FocusSpeed ) return 'Focus Speed';

		else if( this.metric == MindsetMetric.Coachability ) return 'Coachability';
		else if( this.metric == MindsetMetric.AttentionToDetail ) return 'Attention To Detail';
		else if( this.metric == MindsetMetric.Conventionality ) return 'Conventionality';
		else if( this.metric == MindsetMetric.GrowthMindset ) return 'Growth Mindset';
		else if( this.metric == MindsetMetric.InformationProcessing ) return 'Information Processing';

		return '';
	}

	get ToolTip(): string {
		if( !this.mindsetReport ) return 'Mindset Report not available';

		return `${this.MindsetLabel} - ${this.MindsetValueString}`
	}
}
