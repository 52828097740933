import { render, staticRenderFns } from "./ScoutingChip.vue?vue&type=template&id=32ed4c28&scoped=true&"
import script from "./ScoutingChip.vue?vue&type=script&lang=ts&"
export * from "./ScoutingChip.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ed4c28",
  null
  
)

export default component.exports