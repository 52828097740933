
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { MetricData } from '@/../types/interfaces';
import { AthleteMetric, PerformanceCategory } from '@/../types/enums';
import { AgeGroup } from '@best-athletes/ba-types';
import { formatDatePretty } from '../../helpers';
import { title, getMetricIcon, assessmentToMetric, assessmentToMetricExpanded } from '../../pipes';
import { AthleteAssessmentDataModel } from '../../models/athlete/AthleteAssessmentDataModel';
import { comparativeDataStore } from '../../store';
import { StatisticModel } from '../../models/statistic';
import { SelfAssessmentModel } from '../../models/athlete/SelfAssessmentModel';

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class AssessmentDataInfoProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	@Prop({ required: true }) private metric: AthleteMetric;
	@Prop({ required: true }) private assessmentData: AthleteAssessmentDataModel | SelfAssessmentModel;
	@Prop({default: () => ([])}) private compareGroups: AgeGroup[];
	@Prop({ type: Boolean, default: true }) loadData: boolean;

	featuresLoaded = false;
	comparativeDataReady = false;

	/** TODO: Don't use this component to load comparative data */
	@Watch('assessmentData')
	async loadComparativeData(refresh: boolean = false): Promise<void>{
		this.comparativeDataReady = false;
		if(!this.assessmentData){
			console.warn("Assessment Data not found");
			return;
		}
		if(this.assessmentData.__type === 'SelfAssessmentModel') return;
		if(refresh){
			await comparativeDataStore.loadComparativeData({ assessmentId: this.assessmentData.id, athleteId: this.AthleteId });
		}
		if(!this.ComparativeDataReady){
			comparativeDataStore.setComparativeGroups({
				assessmentId: this.assessmentData.id,
				ageGroups: this.compareGroups,
				athleteId: this.AthleteId,
			});
		}
		if(!this.ComparisonReady){
			comparativeDataStore.getUserPerformanceCategories({
				assessmentId: this.assessmentData.id,
			});
		}

		this.comparativeDataReady = true;
	}

	created(): void{
		this.loadComparativeData(true);
	}

	get SlotProps(): Record<string, any>{
		return {
			athleteId: this.AthleteId,
			TestDate: this.TestDate,
			Title: this.Title,
			IconComponentName: this.IconComponentName,
			MetricData: this.MetricData,
			ExpandedMetricData: this.ExpandedMetricData,
			ComparativeDataReady: this.ComparativeDataReady,
			ComparativeDataLoading: this.ComparativeDataLoading,
			ComparativeBarChartStats: this.ComparativeBarChartStats,
			PerformanceLevel: this.PerformanceLevel,
			UseDecimal: this.UseDecimal,
			Over18: this.Over18,
			featuresLoaded: this.featuresLoaded,
			comparativeDataReady: this.comparativeDataReady
		};
	}

	get Over18(): boolean{
		return comparativeDataStore.Over18;
	}

	get AthleteId(): string | undefined{
		if(!this.assessmentData) return undefined;
		return this.assessmentData.athleteId;
	}
	get TestDate(): string{
		if(!this.assessmentData) return '';
		return formatDatePretty(this.assessmentData.assessmentDate);
	}

	get Title(): string{
		return title(this.metric);
	}
	get IconComponentName(): string{
		return getMetricIcon(this.metric);
	}
	get MetricDataMissing(): boolean{
		return this.MetricData.missing === true;
	}
	get MetricData(): MetricData{
		return assessmentToMetric(this.assessmentData, this.metric);
	}
	get ExpandedMetricData(): MetricData{
		return assessmentToMetricExpanded(this.assessmentData, this.metric);
	}

	get UseDecimal(): boolean{
		switch(this.metric){
		case AthleteMetric.Power: return false;
		default: return true;
		}
	}
	get ComparativeDataReady(): boolean{
		return comparativeDataStore.ComparativeDataReady;
	}
	get ComparativeDataLoading(): boolean{
		return comparativeDataStore.comparativeDataLoading;
	}
	get ComparativeBarChartStats(): StatisticModel[]{
		return comparativeDataStore.statistics;
	}
	get ComparisonReady(): boolean{
		return comparativeDataStore.ComparisonReady;
	}
	get PerformanceLevel(): PerformanceCategory | null{
		if(this.MetricDataMissing) return null;
		if(comparativeDataStore.comparison === null) return null;
		return comparativeDataStore.comparison[this.metric];
	}
}
