
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { ScoutingReportEvaluationMatrix } from '@best-athletes/ba-types';
import { FourCornersMetric, SixMomentsMetric, CrispMetric } from '@/../types/enums';

@Component
export default class ScoutingChip extends Mixins(VuetifyMixin){
	@Prop({ default: null }) scoutingReport: ScoutingReportEvaluationMatrix | null;
	@Prop() metric: FourCornersMetric | SixMomentsMetric | CrispMetric;

	get ScoutingValue(): number {
		if( !this.scoutingReport ) return 0;

		else if( this.metric == FourCornersMetric.Technical ) return this.scoutingReport.technical;
		else if( this.metric == FourCornersMetric.Tactical ) return this.scoutingReport.tactical;
		else if( this.metric == FourCornersMetric.Physical ) return this.scoutingReport.physical;
		else if( this.metric == FourCornersMetric.Mental ) return this.scoutingReport.mental;

		else if( this.metric == SixMomentsMetric.AttackingOrganization ) return this.scoutingReport.attackingOrganization;
		else if( this.metric == SixMomentsMetric.AttackingTransition ) return this.scoutingReport.attackingTransition;
		else if( this.metric == SixMomentsMetric.AttackingSetPlays ) return this.scoutingReport.attackingSetPlays;
		else if( this.metric == SixMomentsMetric.DefendingOrganization ) return this.scoutingReport.defendingOrganization;
		else if( this.metric == SixMomentsMetric.DefendingTransition ) return this.scoutingReport.defendingTransition;
		else if( this.metric == SixMomentsMetric.DefendingSetPlays ) return this.scoutingReport.defendingSetPlays;

		else if( this.metric == CrispMetric.Competitive ) return this.scoutingReport.competitive;
		else if( this.metric == CrispMetric.Resilience ) return this.scoutingReport.resilience;
		else if( this.metric == CrispMetric.Intelligence ) return this.scoutingReport.intelligence;
		else if( this.metric == CrispMetric.Speed ) return this.scoutingReport.speed;
		else if( this.metric == CrispMetric.Presence ) return this.scoutingReport.presence;

		return 0;
	}

	get ScoutingValueString(): string {
		if( !this.scoutingReport ) return "-";

		return this.ScoutingValue.toFixed(2);
	}

	get ToolTipColor(): string {
		if( !this.scoutingReport ) return 'error';
		if( this.ScoutingValue <= 0 ) return 'error';

		return this.ScoutingColor;
	}

	get ScoutingColor(): string {
		if( !this.scoutingReport ) return 'primary--text';

		else if( this.ScoutingValue >= 4.5 ) return 'baColorInternational';
		else if( this.ScoutingValue >= 3.5 ) return 'baColorProfessional';
		else if( this.ScoutingValue >= 2.5 ) return 'baColorHighPerformance';
		else if( this.ScoutingValue >= 1.5 ) return 'baColorCompetitive';
		else if( this.ScoutingValue > 0 ) return 'baColorRecreational';

		return 'primary--text';
	}

	get ScoutingLabel(): string {
		if( !this.scoutingReport ) return '';

		else if( this.metric == FourCornersMetric.Technical ) return 'Technical';
		else if( this.metric == FourCornersMetric.Tactical ) return 'Tactical';
		else if( this.metric == FourCornersMetric.Physical ) return 'Physical';
		else if( this.metric == FourCornersMetric.Mental ) return 'Mental';

		else if( this.metric == SixMomentsMetric.AttackingOrganization ) return 'Att Org';
		else if( this.metric == SixMomentsMetric.AttackingTransition ) return 'Att Transition';
		else if( this.metric == SixMomentsMetric.AttackingSetPlays ) return 'Att Set Plays';
		else if( this.metric == SixMomentsMetric.DefendingOrganization ) return 'Def Org';
		else if( this.metric == SixMomentsMetric.DefendingTransition ) return 'Def Transition';
		else if( this.metric == SixMomentsMetric.DefendingSetPlays ) return 'Def Set Plays';

		else if( this.metric == CrispMetric.Competitive ) return 'Competitive';
		else if( this.metric == CrispMetric.Resilience ) return 'Resilience';
		else if( this.metric == CrispMetric.Intelligence ) return 'Intelligence';
		else if( this.metric == CrispMetric.Speed ) return 'Speed';
		else if( this.metric == CrispMetric.Presence ) return 'Presence';

		return '';
	}

	get ToolTip(): string {
		if( !this.scoutingReport ) return 'Scouting Report not available';

		return `${this.ScoutingLabel} - ${this.ScoutingValueString}`
	}
}
