
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { AthleteAssessmentDataModel } from '@/models/athlete/AthleteAssessmentDataModel';
import { Comparison } from '@/../types/interfaces/Statistics';
import { AthleteMetric } from '@/../types/enums';
import { MetricData } from '@/../types/interfaces';
import { assessmentToMetric } from '@/pipes/assessment-to-metric.pipe';
import { getMetricUnitLabel } from '@/pipes/metric-unit-label.pipe';
import { performanceCategoryToColor, performanceCategoryToLabel } from '@/pipes/enum.pipes';

@Component
export default class AssessmentChip extends Mixins(VuetifyMixin){
	@Prop({ default: null }) assessment: AthleteAssessmentDataModel | null;
	@Prop({ default: null }) comparison: Comparison | null;
	@Prop() metric: AthleteMetric;
	@Prop({ type: Boolean }) derived;	// use derived measurements instead of measured (e.g. 'Speed' instead of '10m sprint time')

	get PhysicalAssessmentValueString(): string {
		const metricData = this.MetricData;
		return this.HasMetricData? `${metricData.value}${metricData.unit}` : '-';
	}

	get ToolTipColor(): string {
		if( !this.comparison ) return 'error';
		if( !this.HasMetricData ) return 'error';
		return this.AssessmentColor;
	}

	get AssessmentColor(): string {
		if( !this.comparison )  return 'primary--text';
		if( !this.HasMetricData ) return 'primary--text';
		return performanceCategoryToColor(this.comparison[this.metric]);
	}
	get MetricData(): MetricData {
		return assessmentToMetric(this.assessment, this.metric, this.derived);
	}
	get HasMetricData(): boolean {
		const metricData = this.MetricData;
		if( !metricData ) return false;
		return( metricData.value && (metricData.value != '0') && (metricData.value != '') && (metricData.value != '--') );
	}
	get HasComparison(): boolean {
		return !!this.comparison;
	}
	get ToolTip(): string {
		if( !this.assessment ) return "Physical Assessment not available";

		const label = getMetricUnitLabel(this.metric, false, this.derived);
		const value = this.PhysicalAssessmentValueString;
		if( this.HasMetricData ) {
			if( this.HasComparison ) {
				const level = performanceCategoryToLabel(this.comparison[this.metric]);
				return `${level} Level : ${value} ${label}`;
			}
			return `${value} ${label}`;
		}
		return `${label} data not available`;
	}
}
