
import { Component, Mixins } from 'vue-property-decorator';
import { orgAdminStore } from '@/store';
import { VuetifyMixin } from '@/mixins';
import Page from '@/views/Page.vue';
import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';
import { OrganizationModel } from '@/models/organization/OrganizationModel';

@Component({
	components: {
		Page
	}
})
export default class OrgAdminDashboard extends Mixins(VuetifyMixin){
	created(): void{
		orgAdminStore.loadOrganizations();
	}

	search = '';

	headers = [
		{
			text: 'Organization',
			align: 'start',
			sortable: true,
			value: 'name'
		},
		{ text: 'Location', value: 'Location' },
		{ text: 'League', value: 'league' },
		{ text: 'Type', value: 'type' },
		{ text: 'Teams', value: 'TeamCount' },
		{ text: '', value: 'view' }
	]

	onClickOrg(item, event) {
		this.openOrg(item.id);
	}

	get Loading(): boolean{
		return !orgAdminStore.organizationsInitialized || orgAdminStore.organizationsLoading;
	}
	get OrgsReady(): boolean{
		return orgAdminStore.organizationsInitialized;
	}
	get Organizations(): OrganizationModel[]{
		return orgAdminStore.organizations;
	}

	openOrg(organizationId: string): void{
		this.$router.push({
			name: OrgAdminRoutes.OrgAdminManageTeams,
			params:{
				organizationId,
			}
		});
	}

}
